import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import NotFound from '@/components/common/NotFound.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/popupdemo',
    name: 'popupdemo',
    component: () => import(/* webpackChunkName: "login" */ '../views/PopupDemo.vue'),
    meta: {
      header: {
        logo: false,
      },
      restricted: false,
      isIframe: false,
    },
  },
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "login" */ '../views/Login.vue'),
    meta: {
      header: {
        logo: false,
      },
      restricted: false,
      isIframe: false,
    },
  },
  {
    path: '/register',
    name: 'register',
    component: () => import(/* webpackChunkName: "register" */ '../views/Register.vue'),
    meta: {
      header: {
        logo: false,
      },
      restricted: false,
      isIframe: false,
    },
  },
  {
    path: '/forgotpwd',
    name: 'forgot_password',
    component: () => import(/* webpackChunkName: "forgot_password" */ '../views/ForgotPassword.vue'),
    meta: {
      header: {
        logo: false,
      },
      restricted: false,
      isIframe: false,
    },
  },
  {
    path: '/resetpwd/:token',
    name: 'reset_password',
    component: () => import(/* webpackChunkName: "reset_password" */ '../views/ResetPassword.vue'),
    meta: {
      header: {
        logo: false,
      },
      restricted: false,
      isIframe: false,
    },
  },
  {
    path: '/users',
    name: 'users',
    component: () => import(/* webpackChunkName: "user" */ '../views/User.vue'),
    meta: {
      restricted: true,
      menuKey: 'users',
      hasLeftSidebar: false,
      isIframe: false,
    },
  },
  {
    path: '/settings',
    name: 'settings',
    component: () => import(/* webpackChunkName: "user" */ '../views/UserProfile.vue'),
    meta: {
      restricted: true,
      menuKey: 'settings',
      hasLeftSidebar: false,
      isIframe: false,
    },
  },
  {
    path: '/',
    name: 'home',
    component: () => import(/* webpackChunkName: "home" */ '../views/Apps.vue'),
    meta: {
      restricted: true,
      menuKey: 'home',
      hasLeftSidebar: false,
      isIframe: false,
    },
  },
  {
    path: '/scenario-maker/:appId/:isReport?/:isTestMode?/:campaignId?',
    name: 'scenario_maker',
    component: () => import(/* webpackChunkName: "scenario-maker" */ '../components/scenarios/ScenariosMakerFrame.vue'),
    meta: {
      restricted: false,
      isIframe: true,
      menuKey: 'scenario_maker',
      hasLeftSidebar: false,
      isHiddenLeftSidbar: true,
    },
  },
  {
    path: '/story-scenario-maker/:appId/:isReport?/:isTestMode?/:campaignType?/:index?/:campaignId?/:isReadOnly?',
    name: 'story_scenario_maker',
    component: () =>
      import(/* webpackChunkName: "scenario-maker" */ '../components/story/scenarios/ScenariosMakerFrame.vue'),
    meta: {
      restricted: false,
      isIframe: true,
      menuKey: 'story_scenario_maker',
      hasLeftSidebar: false,
      isHiddenLeftSidbar: true,
    },
  },
  {
    path: '/apps/:app_id',
    name: 'app_detail',
    component: () => import(/* webpackChunkName: "app_detail" */ '../views/AppDetails.vue'),
    meta: {
      restricted: true,
      isIframe: false,
      menuKey: 'app-new',
      hasLeftSidebar: true,
    },
    children: [
      {
        path: '/apps/overview/:app_id',
        name: 'overview',
        component: () => import(/* webpackChunkName: "overview" */ '../components/overview/OverView.vue'),
        meta: {
          restricted: true,
          isIframe: false,
          menuKey: 'overview',
          hasLeftSidebar: true,
        },
      },
      {
        path: '/apps/overview/detail/:app_id/:from_date?/:to_date?',
        name: 'overview_details',
        component: () => import(/* webpackChunkName: "popup_setting" */ '../components/overview/OverViewDetails.vue'),
        meta: {
          restricted: true,
          isIframe: false,
          menuKey: 'overview_details',
          hasLeftSidebar: true,
        },
      },
      {
        path: '/apps/information/:app_id',
        name: 'app_information',
        component: () => import(/* webpackChunkName: "app_information" */ '../components/app/AppInformation.vue'),
        meta: {
          restricted: true,
          isIframe: false,
          menuKey: 'app_information',
          hasLeftSidebar: true,
          title: 'menu.app_information',
        },
      },
      {
        path: '/apps/:app_id/initials',
        name: 'initials',
        component: () =>
          import(/* webpackChunkName: "initials" */ '../components/initial-campaign/InitialCampaignList.vue'),
        meta: {
          restricted: true,
          isIframe: false,
          menuKey: 'initials',
          hasLeftSidebar: true,
        },
      },
      {
        path: '/apps/:app_id/initials/setting/:action_type?/:initial_id?/:scenario_template_id?',
        name: 'initial_setting',
        component: () =>
          import(/* webpackChunkName: "initial_setting" */ '../components/initial-campaign/CampaignSettingForm.vue'),
        meta: {
          restricted: true,
          isIframe: false,
          menuKey: 'initial_setting',
          hasLeftSidebar: true,
          breadcrumb: [
            {
              name: 'initials',
              label: 'menu.inital_message_campaigns',
            },
            {
              name: 'initials',
              label: 'label.campaign_settings',
            },
          ],
        },
      },
      {
        path: '/apps/:app_id/:campaign_type?/details/:campaign_id?/:from_date?/:to_date?',
        name: 'initial_details',
        component: () =>
          import(/* webpackChunkName: "initial_setting" */ '../components/common/ui/ChartOfCampaignDetails.vue'),
        meta: {
          restricted: true,
          isIframe: false,
          menuKey: 'initial_details',
          hasLeftSidebar: true,
          breadcrumb: [
            {
              name: 'initials',
              label: 'menu.inital_message_campaigns',
            },
            {
              name: 'initials',
              label: 'label.campaign_details',
            },
          ],
        },
      },
      {
        path: '/apps/:app_id/pushes',
        name: 'pushes',
        component: () => import(/* webpackChunkName: "pushes" */ '../components/push-campaign/PushCampaignList.vue'),
        meta: {
          restricted: true,
          isIframe: false,
          menuKey: 'pushes',
          hasLeftSidebar: true,
        },
      },
      {
        path: '/apps/:app_id/pushes/setting/:action_type?/:push_id?/:scenario_template_id?',
        name: 'push_setting',
        component: () =>
          import(/* webpackChunkName: "push_setting" */ '../components/push-campaign/PushCampaignSettingForm.vue'),
        meta: {
          restricted: true,
          isIframe: false,
          menuKey: 'push_setting',
          hasLeftSidebar: true,
          breadcrumb: [
            {
              name: 'pushes',
              label: 'menu.push_mespage_campaigns',
            },
            {
              name: 'push_setting',
              label: 'label.campaign_settings',
            },
          ],
        },
      },
      {
        path: '/apps/:app_id/:campaign_type?/details/:campaign_id?/:from_date?/:to_date?',
        name: 'push_details',
        component: () =>
          import(/* webpackChunkName: "initial_setting" */ '../components/common/ui/ChartOfCampaignDetails.vue'),
        meta: {
          restricted: true,
          isIframe: false,
          menuKey: 'push_details',
          hasLeftSidebar: true,
          breadcrumb: [
            {
              name: 'pushes',
              label: 'menu.push_mespage_campaigns',
            },
            {
              name: 'pushes',
              label: 'label.campaign_details',
            },
          ],
        },
      },
      {
        path: '/apps/:app_id/assets',
        name: 'assets',
        component: () => import(/* webpackChunkName: "assets" */ '../components/assets/Assets.vue'),
        meta: {
          restricted: true,
          isIframe: false,
          menuKey: 'assets',
          hasLeftSidebar: true,
        },
      },
      {
        path: '/apps/:app_id/builder',
        name: 'builder',
        component: () => import(/* webpackChunkName: "builder" */ '../components/audience/AudienceList.vue'),
        meta: {
          restricted: true,
          isIframe: false,
          menuKey: 'builder',
          hasLeftSidebar: true,
        },
      },
      {
        path: '/apps/:app_id/scenario-report',
        name: 'scenario_report',
        component: () => import(/* webpackChunkName: "scenario_report" */ '../components/story/ScenarioReport.vue'),
        meta: {
          restricted: true,
          isIframe: false,
          menuKey: 'scenario_report',
          hasLeftSidebar: true,
        },
      },
      {
        path: '/apps/:app_id/q-a-report',
        name: 'q_a_report',
        component: () => import(/* webpackChunkName: "q_a_report" */ '../components/registered-users/QAReport.vue'),
        meta: {
          restricted: true,
          isIframe: false,
          menuKey: 'q_a_report',
          hasLeftSidebar: true,
        },
      },
      {
        path: '/apps/:app_id/form-report',
        name: 'form_report',
        component: () => import(/* webpackChunkName: "form_report" */ '../components/registered-users/FormReport.vue'),
        meta: {
          restricted: true,
          isIframe: false,
          menuKey: 'form_report',
          hasLeftSidebar: true,
        },
      },
      {
        path: '/apps/:app_id/rich-menus',
        name: 'rich_menus',
        component: () => import(/* webpackChunkName: "rich_menus" */ '../components/rich-menus/RichMenus.vue'),
        meta: {
          restricted: true,
          isIframe: false,
          menuKey: 'rich_menus',
          hasLeftSidebar: true,
        },
      },
      {
        path: '/apps/:app_id/rich-menus/setting/:action_type?/:rich_menu_id?',
        name: 'rich_menu_setting',
        component: () =>
          import(/* webpackChunkName: "initial_setting" */ '../components/rich-menus/RichMenuSettingForm.vue'),
        meta: {
          restricted: true,
          isIframe: false,
          menuKey: 'rich_menu_setting',
          hasLeftSidebar: true,
          breadcrumb: [
            {
              name: 'rich_menus',
              label: 'menu.rich_menu_builder',
            },
            {
              name: 'initials',
              label: 'label.rich_menu_setting',
            },
          ],
        },
      },
      {
        path: '/apps/:app_id/target-settings',
        name: 'target_settings',
        component: () =>
          import(/* webpackChunkName: "target_settings" */ '../components/target-setting/TargetSettingList.vue'),
        meta: {
          restricted: true,
          isIframe: false,
          menuKey: 'target_settings',
          hasLeftSidebar: true,
        },
      },
      {
        path: '/apps/:app_id/campaigns',
        name: 'campaigns',
        component: () => import(/* webpackChunkName: "campaigns" */ '../components/campaign/CampaignList.vue'),
        meta: {
          restricted: true,
          isIframe: false,
          menuKey: 'campaigns',
          hasLeftSidebar: true,
          title: 'menu.user_acquisitions',
        },
      },
      {
        path: '/apps/:app_id/stories',
        name: 'stories',
        component: () => import(/* webpackChunkName: "campaigns" */ '../components/story/StoryList.vue'),
        meta: {
          restricted: true,
          isIframe: false,
          menuKey: 'stories',
          hasLeftSidebar: true,
        },
      },
      {
        path: '/apps/:app_id/stories/setting/:action_type?/:story_id?',
        name: 'story_setting',
        component: () => import(/* webpackChunkName: "story_setting" */ '../components/story/StorySettingForm.vue'),
        meta: {
          restricted: true,
          isIframe: false,
          menuKey: 'story_setting',
          hasLeftSidebar: true,
          breadcrumb: [
            {
              name: 'stories',
              label: 'menu.stories',
            },
            {
              name: 'stories',
              label: 'label.story_settings',
            },
          ],
        },
      },
      {
        path: '/apps/:app_id/popups/:campaign_id',
        name: 'popups',
        component: () => import(/* webpackChunkName: "popups" */ '../components/popup/PopupList.vue'),
        meta: {
          restricted: true,
          isIframe: false,
          menuKey: 'popups',
          hasLeftSidebar: true,
          title: 'menu.user_acquisitions',
          breadcrumb: [
            {
              name: 'campaigns',
              label: 'menu.user_acquisitions',
            },
            {
              name: 'popups',
              label: 'label.popup.popup_list',
            },
          ],
        },
      },
      {
        path: '/apps/:app_id/popups/setting/:campaign_id/:action_type?/:popup_id?',
        name: 'popup_setting',
        component: () => import(/* webpackChunkName: "popup_setting" */ '../components/popup/PopupSettingForm.vue'),
        meta: {
          restricted: true,
          isIframe: false,
          menuKey: 'popup_setting',
          hasLeftSidebar: true,
          breadcrumb: [
            {
              name: 'campaigns',
              label: 'menu.user_acquisitions',
            },
            {
              name: 'popups',
              label: 'label.popup.popup_list',
            },
            {
              name: '',
              label: 'label.popup_settings',
            },
          ],
        },
      },
      {
        path: '/apps/:app_id/campaign-traffic-sources',
        name: 'campaign_traffic_sources',
        component: () =>
          import(
            /* webpackChunkName: "campaign_traffic_sources" */ '../components/campaign-traffic-sources/CampaignList.vue'
          ),
        meta: {
          restricted: true,
          isIframe: false,
          menuKey: 'campaign_traffic_sources',
          hasLeftSidebar: true,
          title: 'menu.campaign_traffic_sources',
        },
      },
      {
        path: '/apps/:app_id/traffic-sources/:campaign_id/:is_test_mode?',
        name: 'traffic_sources',
        component: () =>
          import(/* webpackChunkName: "traffic_sources" */ '../components/traffic-sources/TrafficSourceList.vue'),
        meta: {
          restricted: true,
          isIframe: false,
          menuKey: 'traffic_sources',
          hasLeftSidebar: true,
          title: 'menu.campaign_traffic_sources',
          breadcrumb: [
            {
              name: 'campaign_traffic_sources',
              label: 'menu.campaign_traffic_sources',
            },
            {
              name: 'traffic_sources',
              label: 'label.traffic_source.traffic_sources_list',
            },
          ],
        },
      },
      {
        path: '/apps/:app_id/scenario-templates',
        name: 'scenario_templates',
        component: () =>
          import(/* webpackChunkName: "campaigns" */ '../components/scenario-template/ScenarioTemplateList.vue'),
        meta: {
          restricted: true,
          isIframe: false,
          menuKey: 'scenario_templates',
          hasLeftSidebar: true,
        },
      },
      {
        path: '/apps/:app_id/scenario-templates/setting/:action_type?/:scenario_template_id?',
        name: 'scenario_template_setting',
        component: () =>
          import(
            /* webpackChunkName: "scenario_setting" */ '../components/scenario-template/ScenarioTemplateSettingForm.vue'
          ),
        meta: {
          restricted: true,
          isIframe: false,
          menuKey: 'scenario_template_setting',
          hasLeftSidebar: true,
          breadcrumb: [
            {
              name: 'scenario_templates',
              label: 'menu.scenario_templates',
            },
            {
              name: 'scenario_template_setting',
              label: 'label.scenario_template_settings',
            },
          ],
        },
      },
      {
        path: '/apps/:app_id/media-manger',
        name: 'media_manager',
        component: () => import(/* webpackChunkName: "campaigns" */ '../components/media-manager/MediaManager.vue'),
        meta: {
          restricted: true,
          isIframe: false,
          menuKey: 'media_manager',
          hasLeftSidebar: true,
        },
      },
      {
        path: '/apps/:app_id/response-setting',
        name: 'response_setting',
        component: () => import(/* webpackChunkName: "campaigns" */ '../components/auto-response/AutoResponses.vue'),
        meta: {
          restricted: true,
          isIframe: false,
          menuKey: 'response_setting',
          hasLeftSidebar: true,
        },
      },
    ],
  },
  {
    path: '/apps/line-users/:app_id',
    name: 'app_line_users',
    component: () => import(/* webpackChunkName: "app_line_users" */ '../components/app/LineUsers.vue'),
    meta: {
      restricted: true,
      isIframe: false,
      menuKey: 'app_line_users',
      hasLeftSidebar: true,
    },
  },
  {
    path: '/apps/line-users/:app_id/profile/:user_id-:uid-:is_test_mode',
    name: 'app_line_user_profile',
    component: () => import(/* webpackChunkName: "app_line_users" */ '../components/app/LineUserProfile.vue'),
    meta: {
      restricted: true,
      isIframe: false,
      menuKey: 'app_line_user_profile',
      hasLeftSidebar: true,
      breadcrumb: [
        {
          name: 'app_line_users',
          label: 'menu.app_line_users',
        },
      ],
    },
  },
  {
    path: '/help',
    name: 'help',
    component: () => import(/* webpackChunkName: "help" */ '../views/Help.vue'),
    meta: {
      restricted: true,
      isIframe: false,
      menuKey: 'help',
      hasLeftSidebar: true,
    },
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'notFound',
    component: NotFound,
    meta: {
      header: {
        logo: false,
        homeButtton: true,
      },
      restricted: false,
      isIframe: false,
    },
    props: true,
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

// router.beforeEach((to, from, next) => {
//   console.log('Route Meta:', to.meta)
//   console.log('isIframe:', to.meta.isIframe)
//   next()
// })

router.afterEach(() => {
  window.scrollTo(0, 0)
})

export default router
