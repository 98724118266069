import { ACTION_SCENARIO_TEMPLATE } from './actions'
import { ICommonRequest, IScenarioTemplate } from '@/utils/types'
import { apiListChangedHandler } from '@/utils/helpers'
import api from '@/api/scenarioTemplate'
import cloneDeep from 'lodash/cloneDeep'
import { localStore } from '@/utils/localstore'
import { constant } from '@/utils/constants'

type TState = {
  items: IScenarioTemplate[]
  loading: boolean
}

export default {
  state: {
    items: [],
    loading: false,
  },
  getters: {
    scenarioTemplates: (state: TState) => {
      return state.items as IScenarioTemplate[]
    },
  },
  mutations: {
    // [List]
    [ACTION_SCENARIO_TEMPLATE.SET_ITEMS]: (state: TState, { items }: { items: IScenarioTemplate[] }) => {
      state.items = cloneDeep(items)
    },

    // [New item]
    [ACTION_SCENARIO_TEMPLATE.SET_NEW_ITEM]: (state: TState, { item }: { item: IScenarioTemplate }) => {
      state.items = apiListChangedHandler<IScenarioTemplate>(state.items, cloneDeep(item), 'added')
    },

    // [UPDATE]]
    [ACTION_SCENARIO_TEMPLATE.SET_UPDATED_ITEM]: (state: TState, { item }: { item: IScenarioTemplate }) => {
      state.items = apiListChangedHandler<IScenarioTemplate>(state.items, cloneDeep(item), 'modified')
    },

    // [Deleted item]
    [ACTION_SCENARIO_TEMPLATE.SET_DELETED_ITEM]: (state: TState, { item }: { item: IScenarioTemplate }) => {
      state.items = apiListChangedHandler<IScenarioTemplate>(state.items, cloneDeep(item), 'removed')
    },
  },
  actions: {
    // eslint-disable-next-line
    [ACTION_SCENARIO_TEMPLATE.LOAD_ITEMS]: async ({ commit }, params: ICommonRequest) => {
      const key = constant.LOCAL_KEYS.SCENARIO_TEMPLATES + params.app_id + JSON.stringify(params)
      let resp
      resp = localStore.getWithExpiry(key)
      if (!resp) {
        resp = await api.list(params)
        localStore.setWithExpiry(key, resp, constant.LOCAL_EXPIRED)
      }
      commit(ACTION_SCENARIO_TEMPLATE.SET_ITEMS, { items: resp })
      return resp
    },
    // eslint-disable-next-line
    [ACTION_SCENARIO_TEMPLATE.LOAD_ITEMS_WITHOUT_DETAIL]: async ({ commit }, params: ICommonRequest) => {
      const key = constant.LOCAL_KEYS.SCENARIO_TEMPLATES + params.app_id + '-without-detail-' + JSON.stringify(params)
      let resp
      resp = localStore.getWithExpiry(key)
      if (!resp) {
        resp = await api.getAllItemsWithoutDetail(params)
        localStore.setWithExpiry(key, resp, constant.LOCAL_EXPIRED)
      }
      commit(ACTION_SCENARIO_TEMPLATE.SET_ITEMS, { items: resp })
      return resp
    },
    // eslint-disable-next-line
    [ACTION_SCENARIO_TEMPLATE.LOAD_ITEM]: async ({ commit }, id: string) => {
      return await api.single(id)
    },
    [ACTION_SCENARIO_TEMPLATE.ADD]: async ({ commit }, payload: IScenarioTemplate) => {
      const resp = await api.add(payload)
      if (resp?._id) {
        localStore.removeStartsWith(constant.LOCAL_KEYS.SCENARIO_TEMPLATES + resp.app_id)
        commit(ACTION_SCENARIO_TEMPLATE.SET_NEW_ITEM, { item: resp })
        return resp
      }
    },
    [ACTION_SCENARIO_TEMPLATE.UPDATE]: async ({ commit }, payload: IScenarioTemplate) => {
      const resp = await api.update(payload)
      if (resp?._id) {
        localStore.removeStartsWith(constant.LOCAL_KEYS.SCENARIO_TEMPLATES + resp.app_id)
        commit(ACTION_SCENARIO_TEMPLATE.SET_UPDATED_ITEM, { item: resp })
        return resp
      }
    },
    [ACTION_SCENARIO_TEMPLATE.DELETE]: async ({ commit }, id: string) => {
      const resp = await api.delete(id)
      if (resp?._id) {
        localStore.removeStartsWith(constant.LOCAL_KEYS.SCENARIO_TEMPLATES + resp.app_id)
        commit(ACTION_SCENARIO_TEMPLATE.SET_DELETED_ITEM, { item: resp })
        return true
      }
    },
  },
}
